import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/user/Login'));
const Forgot = React.lazy(() => import('./views/user/Forgot'));
const Reset = React.lazy(() => import('./views/user/Reset'));

function App() {
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" name="Login Page" element={<Login />}></Route>
          <Route path="/forgot" name="Forgot Password" element={<Forgot />} ></Route>
          <Route path="/reset" element={<Reset />}></Route>
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
